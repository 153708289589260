import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';

import { indexBy, prop } from 'ramda';
import { map, tap } from 'rxjs';

import { SYSTEM_PROPERTY_FILES_ID } from '@alan-apps/api-interfaces';
import {
  AllCitiesGQL,
  AllDistrictsGQL,
  SystemPropertyFilesGQL,
  SystemPropertyFilesUpload,
} from '@alan-apps/data-access';
import { BaseHttpService, cache, ICache } from '@nghedgehog/angular-ui';

export interface CheckedItem {
  id: number;
  name: string;
  checked: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SystemService implements ICache {
  constructor(
    private _http: BaseHttpService,
    private allDistricts: AllDistrictsGQL,
    private allCities: AllCitiesGQL,
    private systemPropertyFilesGQL: SystemPropertyFilesGQL,
    private _fb: FormBuilder,
  ) {
    // directly subscribe to get data before view property
    this.getSystemPropertyFilesPhotos().subscribe();
  }
  readonly storageKey = 'SystemService';

  @cache()
  getCities(onlyProperty = false) {
    return this._http.apollo(this.allCities.fetch({ onlyProperty }));
  }

  @cache()
  getCityDistricts(city: number, onlyProperty = false) {
    return this._http.apollo(this.allDistricts.fetch({ city, onlyProperty }));
  }

  @cache()
  getSystemPropertyFilesPhotos() {
    return this._http
      .apollo(
        this.systemPropertyFilesGQL.fetch({ id: SYSTEM_PROPERTY_FILES_ID }),
      )
      .pipe(
        map((files) => {
          if (files) {
            const source = files.dataJSON as SystemPropertyFilesUpload[];

            const map = indexBy(prop('id'), source);
            return map;
          }
          return {};
        }),
      );
  }

  addCheckFormArrayTap(formArray: FormArray<any>) {
    return tap((items: any[]) => {
      items.forEach((item) => {
        formArray.push(this.createCheckedFormGroup(item));
      });
    });
  }

  createCheckedFormGroup(
    type: { id?: any; value?: any; name?: string },
    initState: boolean = null,
  ) {
    return this._fb.group({
      checked: [initState, []],
      id: [type.id ?? type.value, []],
      name: [type.name, []],
    });
  }

  checkRouteParams(
    [key, value]: [key: string, value: string],
    formGroup: FormGroup | AbstractControl<any, any>,
  ) {
    const items = value.split(',');

    const form = formGroup.get(key);
    if (form) {
      if (form instanceof FormArray) {
        form.controls?.forEach((control) => {
          if (items.includes(`${control.value.id}`)) {
            control.patchValue({
              ...control.value,
              checked: true,
            });
          }
        });
      } else {
        form.patchValue(value);
      }
    }
  }
}
